import React , {useEffect, useState} from 'react'

import Box from '@mui/material/Box' 
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import AppBar from '@mui/material/AppBar' 
import Toolbar from '@mui/material/Toolbar' 


import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia' 

import IconButton from '@mui/material/IconButton' 
import Button from '@mui/material/Button' 

import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip' 

import { getMarketProducts, getMarketProductDetail,  postCart, deleteFromCart } from '../Session/AuthSession' 
import {getLocalAccessToken} from '../Session/AuthSession'

import { Alert, Dialog, DialogContent } from '@mui/material'


import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import CloseIcon from '@mui/icons-material/Close'
import { ConstructionOutlined, ContactsOutlined, ImageSearch } from '@mui/icons-material'

import { Slide } from '@mui/material'


import Message from '../Components/Message'  
import MiniNav
 from '../Components/MiniNav'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
});

const fillerImage = {
    url: 'https://meadowbrookhealth.com/wp-content/uploads/2016/01/blank-photo.png'
}
function Store() {

    //check authentication 
    const token = getLocalAccessToken() 

    const [products, setProducts] = useState([]) 
    
    //product detail
    const [productDetailStatus, setProductDetailStatus] = useState(false) 
    const [productDetailID, setProductDetailID] = useState() 
    const [productDetailName, setProductDetailName] = useState() 
    const [productDetailDesc, setProductDetailDesc] = useState() 
    const [productDetailPrice, setProductDetailPrice] = useState() 
    const [productDetailSale, setProductDetailSale] = useState(false)  
    const [productDetailQuantity, setProductDetailQuantity] = useState() 
    const [productDetailImages, setProductDetailImages] = useState([fillerImage]) 
    const [productDetailType, setProductDetailType] = useState() 
    const [productDetailMarket, setProductDetailMarket] = useState() 
    const [productDetailCurrency, setProductDetailCurrency] = useState() 


    //cart 

    const [addCart, setAddCart] = useState() 


    //message 
    const [messageInfo, setMessageInfo] = useState() 
    const [messageIndicator, setMessageIndicator] = useState() 
    const [messageStatus, setMessageStatus] = useState(false) 

    const handleMessageStatus = () => {
        setMessageStatus(false) 
    }





    const handleProductDetail = () => {
        setProductDetailStatus(false)
    }



    const mountProductDetail = async (id) => {
        const product = await getMarketProductDetail(id)
                        .then((res) => {
                            
                            setProductDetailName(res.data.name)
                            setProductDetailDesc(res.data.description)
                            setProductDetailPrice(res.data.product_price)
                            setProductDetailSale(res.data.on_sale)
                            setProductDetailQuantity(res.data.quantity) 
                            setProductDetailImages(res.data.product_images) 
                            setProductDetailType(res.data.product_type) 
                            setProductDetailMarket(res.data.on_market) 
                            setProductDetailCurrency(res.data.currency) 
                             
                        })
                        .catch(() => {
                            setMessageInfo('Network Error')
                            setMessageIndicator("error")
                            setMessageStatus(true)
                        })

       
    }


    const addToCartWithoutDialog = (product) => {

        setAddCart({
            name: product.name, 
            description: product.description, 
            product_price: product.product_price, 
            product_images: product.product_images, 
            product_type: product.product_type, 
            on_sale: product.on_sale, 
            on_market: product.on_market, 
            quantity: product.quantity, 
            currency: product.currency,    
        })
    }


    const addToCartWithDialog = () => {
        setAddCart({
            name: productDetailName,  
            description: productDetailDesc, 
            product_price: productDetailPrice, 
            product_images: productDetailImages, 
            product_type: productDetailType, 
            on_sale: productDetailSale, 
            on_market: productDetailMarket, 
            quantity: productDetailQuantity, 
            currency: productDetailCurrency, 
        })
    }

    const submitToCart = async (event) => {
        event.preventDefault() 

        if(!token){
            setMessageInfo('Login to add')
            setMessageIndicator('info')
            setMessageStatus(true)
            return 
        }

        return await postCart(addCart)
                .then(() => {
                    setMessageInfo("Added to Cart")
                    setMessageIndicator("success")
                    setMessageStatus(true) 
                })
                .catch(() => {
                    setMessageInfo("Error")
                    setMessageIndicator("error")
                    setMessageStatus(true) 
                })
    }

    useEffect(() => {

        const mountProducts = async () => {
            const data = await getMarketProducts()
            setProducts(data.data) 
        }
        mountProducts()
    }, [])

    console.log('Store Products: ', products) 

    return (
        <Box 
            mt={{xs: 10, sm: 10, md: 10, lg: 10, xl: 20}}
            display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex"}}
            alignItems="center"
            justifyContent="center"
            maxWidth="100%">



            <Stack 
                direction="column"
                spacing={5}
                alignItems="center"
                justifyContent="center"                 
                maxWidth="100%">


                    <Typography 
                        variant="h3"
                        component="div"
                        fontFamily="monospace">

                           Browse Store

                    </Typography>

                    <Grid
                        container 
                        rowSpacing={{xs: 2, sm: 2, md: 5, lg: 5}}
                        columnSpacing={{xs: 2, sm: 2, md: 2, lg: 5, xl: 5}}
                        alignItems="center" 
                        justifyContent="center" 
                        maxWidth="100%"
                        >


                        {products.map((product, key) => (

                            <Grid item
                                key={key}
                                width={{xs: '80%', sm: '80%', md: '40%', lg: '30%', xl: '25%'}}
                                alignItems="start"
                                justifyContent="start">

                                    <Card 
                                        sx={{ boxShadow: 1}}
                                    >
                                        
                                            <CardMedia 
                                                sx={{height: '20rem'}}
                                                component="img"
                                                image={product.product_images[0].url}
                                                alt={product.name}/>

                                            <CardContent>
                                                <Stack 
                                                    alignItems="center" 
                                                    justifyContent="center" 
                                                    direction="row"
                                                    spacing={5}>

                                                        <Stack 
                                                            direction="column" 
                                                            spacing={0}>

                                                            <Typography 
                                                                variant="h6" 
                                                                component="div" 
                                                                fontFamily="monospace"
                                                            >
                                                                ${product.product_price} 
                                                            </Typography>


                                                            <Typography 
                                                                variant="p" 
                                                                component="div" 
                                                                fontFamily="monospace"
                                                            >
                                                                {product.name} 
                                                            </Typography>


                                                            <Typography 
                                                                variant="subtitle2" 
                                                                component="div" 
                                                                fontFamily="monospace"
                                                            >
                                                                {product.quantity} in stock 
                                                            </Typography>
                                                        </Stack>

                                                        <Stack 
                                                            direction="column"
                                                            spacing={3}
                                                            >
                                                                <form onSubmit={submitToCart}>


                                                                    <Button 
                                                                        variant="contained"
                                                                        sx={{ fontFamily: 'monospace', backgroundColor: 'purple', background: 'purple'}}
                                                                        endIcon={<ShoppingCartIcon/>}
                                                                        onClick={() => (
                                                                            addToCartWithoutDialog(product)
                                                                        )}
                                                                        type="submit" 
                                                                        >
                                                                            Add
                                                                    </Button>

                                                                </form>


                                                                <Button 
                                                                    variant="contained" 
                                                                    sx={{ fontFamily: 'monospace', backgroundColor: '#880ED4', background: '#880ED4'}}
                                                                    endIcon={<ImageSearch/>}
                                                                    onClick={() => (
                                                                        mountProductDetail(product.id) ,setProductDetailStatus(true) 
                                                                    )}>
                                                                        View 
                                                                </Button>
                                                        </Stack>


                                                </Stack>
                                            </CardContent>
                                        
                                        

                                    </Card>
                                    
                            </Grid>
                        ))}

                    
                    </Grid>
            </Stack>

            <Dialog
                fullScreen
                open={productDetailStatus}
                onClose={handleProductDetail}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', backgroundColor: 'purple', background: 'purple'}}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleProductDetail} 
                        aria-label="close"
                        >
                        <CloseIcon/>
                        </IconButton>
                        
                        <Typography 
                            variant="h5"
                            component="div">
                                {productDetailName} 
                        </Typography>
                       
                    </Toolbar>

                   
                </AppBar>


                <DialogContent>

                    <Stack 
                        direction="column"
                        spacing={8}
                        alignItems="center"
                        justifyContent="center">

                            <Stack 
                                justifyContent={{xs: 'center', sm: 'center'}}
                                alignItems={{xs: 'center', sm: 'center'}}
                                direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row'}}
                                spacing={4}>

                                    <Stack 
                                        direction="column"
                                        spacing={1}>

                                            <CardMedia
                                                sx={{height: '20rem'}}
                                                component="img"
                                                image={productDetailImages[0].url}
                                                alt={productDetailName}>

                                            </CardMedia>


                                    </Stack>
                                    

                                    <Stack 
                                        direction="column"
                                        spacing={1}
                                        width="200px">

                                            <Typography     
                                                variant="h5"
                                                component="div">
                                                    {productDetailName}
                                            </Typography>

                                            <Typography 
                                                variant="subtitle1"
                                                wrap="true"
                                                component="div">
                                                    {productDetailDesc}
                                            </Typography>

                                            {productDetailSale && 
                                                <Alert severity="success">
                                                    On Sale Now 
                                                </Alert>
                                            }

                                            <Typography 
                                                variant="subtitle1"
                                                component="div">
                                                    {productDetailQuantity} left in stock  
                                            </Typography>

                                            <Typography 
                                                variant="h6"
                                                component="div">
                                                    ${productDetailPrice}
                                            </Typography>

                                            <form onSubmit={submitToCart}>

                                                <Button 
                                                    sx={{ fontFamily: 'monospace', backgroundColor: 'purple', background: 'purple'}}
                                                    variant="contained"
                                                    endIcon={<ShoppingCartIcon/>}
                                                    onClick={() => (
                                                        addToCartWithDialog()
                                                    )}
                                                    type="submit"
                                                    >
                                                        Add 
                                                </Button>

                                            </form>
                                    </Stack>


                                    
                            </Stack>

                            <Typography 
                                variant="h5"
                                component="div">
                                    More Images 
                            </Typography>

                            <Stack 
                                direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row'}}
                                spacing={2}>

                                    {productDetailImages.map((image, key) => (
                                        <CardMedia
                                            key={key}
                                            sx={{height: '10rem'}}
                                            component="img"
                                            image={image.url}
                                            alt={productDetailName}>

                                        </CardMedia>

                                    ))}

                            </Stack>

                    </Stack>

                </DialogContent>
            </Dialog>


           <Message messageStatus={messageStatus} message={messageInfo} messageIndicator={messageIndicator} messageClose={handleMessageStatus}/>

        </Box>
        
    )
}

export default Store 