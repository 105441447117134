import React, {useState, useEffect} from 'react'

import Box from '@mui/material/Box' 
import Stack from '@mui/material/Stack' 


import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import Snackbar from '@mui/material/Snackbar'
import Message from '../Components/Message'

import { makeStyles } from '@mui/styles'


import {Link, useNavigate }  from 'react-router-dom' 
import  { createUser, getLocalAccessToken } from '../Session/AuthSession'

const useStyles = makeStyles({
    container: {

        boxShadow: '4px 10px 16px 1px rgba(4,4,4,.3)',
        backgroundColor: 'white',
    
   }
})


function Signup() {

    const classes = useStyles() 
    const navigate = useNavigate() 
    
    const [usernameField, setUsernameField] = useState() 
    const [emailField, setEmailField] = useState() 
    const [passwordField, setPasswordField] = useState() 


    const [messageInfo, setMessageInfo] = useState() 
    const [messageIndicator, setMessageIndicator] = useState() 
    const [messageStatus, setMessageStatus] = useState(false) 

    const handleMessageStatus = () => {
        setMessageStatus(false) 
    }


    const handleUsernameInput = (event) => {
        setUsernameField(event.target.value)
    }

    const handleEmailInput = (event) => {
        setEmailField(event.target.value)
    }

    const handlePasswordInput = (event) => {
        setPasswordField(event.target.value)
    }

    const validateBeforeSubmit = (username, email, password) => {
        if(username.length <= 4 || password.length <= 5){
            console.log('username length: ', username.length)
            console.log('password length: ', password.length)
            return false 
        }
        return true 
    }

    const handleCreateAccount = async (event) => {
        event.preventDefault() 
        const data = {
            username: usernameField, 
            email: emailField, 
            password: passwordField
        }

        if(!validateBeforeSubmit){
            console.log('Check you info!')
            return 
        }

        return await createUser(data)
                .then(() => {
                    setMessageInfo('Account Created. Head to login page ')
                    setMessageIndicator('success')
                    setMessageStatus(true) 

                    })
                .catch(() => {
                    setMessageInfo('Error Creating Account')
                    setMessageIndicator('error')
                    setMessageStatus(true) 
                })

        
    }

    console.log('Username: ', usernameField)
    console.log('Password: ', passwordField)
    console.log('Email: ', emailField)


    useEffect(() => {
        const token = getLocalAccessToken() 
        if(token){
            return  navigate('/')
        }
    })

    return (
        <form onSubmit={handleCreateAccount}>

        <Box 
            mt={{xs: 10, sm: 10, md: 10, lg: 10, xl: 20}}
            display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex"}}
            justifyContent="center"
            alignItems="center"
            maxWidth="100%">

            <Stack 
                width="400px"
                className={classes.container}
                direction="column"
                spacing={3}
                justifyContent="center"
                alignItems="center">

                    <Typography 
                        fontFamily="monospace"
                        fontWeight="bold"
                        variant="h4"
                        component="div">
                            Sign up 
                    </Typography>


                        <TextField 
                            variant="outlined"
                            type="text"
                            label="Username"
                            onChange={handleUsernameInput}>

                        </TextField>

                        <TextField 
                            variant="outlined"
                            type="email"
                            label="Email Address"
                            onChange={handleEmailInput}>

                        </TextField>


                        <TextField 
                            variant="outlined"
                            type="password"
                            label="Password"
                            onChange={handlePasswordInput}>

                        </TextField>

                        <Stack 
                            width="50%"
                            direction="column">
                                <Button 
                                    sx={{ fontFamily: 'monospace'}}
                                    variant="contained"
                                    type="submit">
                                        Sign up  
                                </Button>
                        </Stack>




                   

                    <Stack 
                        direction="column"
                        spacing={1}> 
                        <Typography
                            fontFamily="monospace"
                            variant="p"
                            component="div">
                                Already have an Account? 
                        </Typography>

                        <Button component={Link} to={'/login'}
                            sx={{ fontFamily: 'monospace'}}
                            variant="text">
                                Login 
                        </Button>
                    </Stack>

                  

            </Stack>


            <Message messageStatus={messageStatus} message={messageInfo} messageIndicator={messageIndicator} messageClose={handleMessageStatus}/>


    </Box>
    </form>

    )
}

export default Signup 