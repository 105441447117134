import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack' 

import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem' 

import CssBaseline from '@mui/material/CssBaseline';



import StoreIcon from '@mui/icons-material/Store'
import LoginIcon from '@mui/icons-material/Login'
import AccountBoxIcon from '@mui/icons-material/AccountBox'

import { Routes, Route, Link, BrowserRouter } from "react-router-dom"

import AppIcon from './AppIcon' 
import { Icon } from '@mui/material'
import logo from '../logo/logo512.png'


import HomeScreen from '../Pages/HomeScreen'
import Login from '../Pages/Login'
import Signup from '../Pages/Signup' 

import Profile from '../Pages/Profile'
import Store from '../Pages/Store' 
import Cart from '../Pages/Cart'



function AppMenu() {

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  } 
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <Box  sx={{ backgroundColor: 'white'}}>
      
    <CssBaseline/>
    <AppBar position="fixed"  sx={{ backgroundColor: 'purple', background: 'purple'}} >
      <Container maxWidth="xl" sx={{ backgroundColor: 'purple'}}>
        <Toolbar disableGutters>
            <Tooltip 
                title="Home">
                <IconButton component={Link} to={'/'}
                  variant="contained"
                  >
                  <AppIcon/>
                </IconButton>
            </Tooltip>

         



         
          <Box sx={{ flexGrow: 1 }}>
            
          </Box>

          

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex'  }}}>
            
                <Stack 
                  direction="row"
                  spacing={3}>

                    <Tooltip 
                      title="Browse Store">
                      <IconButton component={Link} to={'/store'}
                        variant="contained"
                        >
                            <StoreIcon 
                                style={{ color: 'white'}}
                                aria-label="Browse Store " 
                                fontSize="large"/> 
                      </IconButton>
                    </Tooltip>

                      <Tooltip 
                        title="login">
                        <IconButton  component={Link} to={'/login'}
                          variant="contained">
                            <LoginIcon 
                                style={{ color: 'white'}}
                                aria-label="Login" 
                                fontSize="large"/>  
                        </IconButton>
                      </Tooltip>

                      <Tooltip 
                        title="Sign up">
                        <IconButton component={Link} to={'/signup'}
                          variant="outlined">
                            <AccountBoxIcon  
                                style={{ color: 'white'}}
                                aria-label="Sign up" 
                                fontSize="large"/>  
                        </IconButton>   
                      </Tooltip>
                </Stack>           
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            GodlyVizion 
          </Typography>


          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, color: 'black'}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              style={{ color: 'white'}}
            >
              <MenuIcon 
                style={{ color: 'white'}}/>
            </IconButton>

           <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >

              <MenuItem>
                <Button component={Link} to={'/login'}
                  variant="text"
                  startIcon={<LoginIcon/>}>
                    Login 
                </Button>

              </MenuItem>

              <MenuItem>
                <Button component={Link} to={'/signup'}
                  variant="text" 
                  startIcon={<AccountBoxIcon/>}>
                    Sign up 
                </Button>

              </MenuItem>

              <MenuItem>
                <Button component={Link} to={'/store'}
                  variant="text"
                  startIcon={<StoreIcon/>}>
                    Store 
                </Button>

              </MenuItem>

              
                 
            </Menu>

          </Box>


        </Toolbar>

      </Container>

      


    </AppBar>

      <Box
            mt={{ xs: 15, sm: 15, md: 15, lg: 10, xl: 10}}
            alignItems="center"
            justifyContent="center">
            <Routes>
              <Route exact path="/" element={<HomeScreen/>} />
              <Route path="/login" element={<Login/>}/>
              <Route path="/signup" element={<Signup/>}/>
              <Route path="/profile" element={<Profile/>}/>
              <Route path="/cart" element={<Cart/>}/>
              <Route path="/store" element={<Store/>}/>



              
            </Routes>
      </Box>

    
    </Box> 
  )
}

export default AppMenu 
