
import {
    Elements,
    CardElement,
    useElements,
    useStripe
  } from "@stripe/react-stripe-js";
  import React, {useEffect, useState} from "react"


import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'

import { retrieveUserInfo, retrieveUserProfile, retrieveUserShippingAddress } from "../Session/AuthSession"
import { savePayment, saveTransaction} from "../Session/AuthSession"

import { Alert, TextField } from "@mui/material";


const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        width: '100%',
        ":-webkit-autofill": {
          color: "#fce883"
        },
        "::placeholder": {
          color: "#87bbfd"
        }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee"
      }
    }
  };


const CheckoutForm = ({total, transaction_price, shipping_address, orders}) => {

    const [userInfo, setUserInfo] = useState() 
    const [userProfile, setUserProfile] = useState() 

    const [billingDetail, setBillingDetail] = useState()

    const [submitPaymentInfo, setSubmitPaymentInfo] = useState() 
    const [transactions, setTransactions] = useState() 
    const [userCart, setUserCart] = useState([])


    const [paymentCompleted, setPaymentCompleted] = useState(false) 

    const stripe = useStripe() 
    const elements = useElements() 


    // save and purchase if user don't exists
    // purchase if user exists 

    const handleSubmit =  (stripe, elements) => async (event) => {

       event.preventDefault() 
       const cardElement = elements.getElement(CardElement) 

       const {paymentMethod, error} = await stripe.createPaymentMethod({
           type: 'card', 
           card: cardElement, 
           billing_details: {
               address: {
                   city: billingDetail.shipping_city,
                   country: 'US', 
                   line1: billingDetail.shipping_address
               },
               email: userInfo.email, 
               name: userInfo.username, 
               phone: userProfile.phone_number, 

           }, 
           
       })

       await savePayment({email: userInfo.email, payment_method_id: paymentMethod.id, total_price: total}) 
                        .then(response => {
                            console.log(response.data)
                            setPaymentCompleted(true) 

                        })
                        .catch(error => {
                            console.error(error)
                        })



        await saveTransaction(transactions).then(( console.log('Success')))
                                             .catch((error) => console.error(error))

        

       if(error){
           console.error(error)
       }
       console.log(paymentMethod) 
    }


    const setupTransactions = () => {
        setTransactions({
            
            shipping: shipping_address, 
            price: transaction_price,   
            payment_status: true, 
            email: userInfo.email, 
            tracking_number: 'none', 
            user_transaction: orders 
            
        })
    }

    useEffect(() => {


        const mountUserProfileInfo = async () => {
            const data = await retrieveUserProfile()
            setUserProfile(data.data[0])
        }

        const mountUserShipping = async () => {
            const data = await retrieveUserShippingAddress()
            setBillingDetail(data.data[0]) 
        }

        const mountUserInfo = async () => {
            const data = await retrieveUserInfo()
            setUserInfo(data.data[0])
        }



       

        mountUserShipping() 
        mountUserInfo() 
        mountUserProfileInfo()
    }, [])

    //console.log('Total Price At CheckoutForm : ', total)
    //console.log('Shipping Address: ', shipping_address)
    //console.log('Transactions: ', transactions) 

    console.log('Orders: ', orders)



    return (    
        <Stack 
            direction="column"
            spacing={3}
            alignItems="center"
            justifyContent="center"
            maxWidth="100%">


                <CardElement options={CARD_OPTIONS}/>

                <form onSubmit={handleSubmit(stripe, elements)}>
                    <Button 
                        variant="contained"
                        onClick={() => (
                            setupTransactions()
                        )}
                        type="submit">
                            Purchase 
                    </Button>
                </form>

                {paymentCompleted &&
                    <Alert severity="success">
                        Payment Successful. Check you Order Status for more Info 
                    </Alert>
                }


            
        </Stack>
)
}

export default CheckoutForm 