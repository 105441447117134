import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'
import CheckoutForm from './CheckoutForm'


//const stripePromise = loadStripe('pk_live_51Kr7G8HCb0lvrcuybYkkvxlL9YIj42z8g8IkzQEfpSwGlbXtWBO94sM93evn8WqH960hWnYM1YWxLE54UCxlMRQ400hlwKIfTv')
const stripePromise = loadStripe('pk_test_51Kr7G8HCb0lvrcuywqlrk3fDTb2DG3L1mCgGICYvTahfiaBIBhIPemWw0v5RTYFWTiSntQiILeeC6iznAfjUma2C00VTMYLC0V')



const StripePaymentForm = (props) => {
    const {total, transaction_price, shipping_address, orders} = props 
    return(
        <Elements stripe={stripePromise}>
            <CheckoutForm total={total} transaction_price={transaction_price} shipping_address={shipping_address} orders={orders}/>
        </Elements>
    )
}

export default StripePaymentForm 