

import React, {useState, useEffect } from 'react'

import Box from '@mui/material/Box' 
import Stack from '@mui/material/Stack' 


import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'


import {Link, Navigate, useNavigate }  from 'react-router-dom' 

import { makeStyles } from '@mui/styles'

import {getLocalAccessToken, signin} from '../Session/AuthSession' 

const useStyles = makeStyles({
    container: {

        boxShadow: '4px 10px 16px 1px rgba(4,4,4,.3)',
        backgroundColor: 'white',
    
   }
})

function Login () {

    const classes = useStyles() 
    const navigate = useNavigate() 

    const [usernameField, setUsernameField] = useState() 
    const [passwordField, setPasswordField] = useState()

    const [redirect, setRedirect] = useState(false)


    const handleUsernameInput = (event) => {
        setUsernameField(event.target.value)
    }

    const handlePasswordInput = (event) => {
        setPasswordField(event.target.value)
    }

    const validateLogin = (username, password) => {

        if(username = 0||  password == 0){
            return false 
        }

        return true 


    }

    const authenticate = async (event) => {
        event.preventDefault() 
        const data = {
            username: usernameField,
            password: passwordField 
        }

        if(!validateLogin(usernameField, passwordField)){
            return console.error("Username and Password cannot be unfilled")
        }


        const auth_data = await signin(data)
                                  
        
        const {access, refresh} = auth_data.data 
        localStorage.setItem('access', access)
        localStorage.setItem('refresh', refresh)   

        navigate('/')

    }

    useEffect(() => {
        const token = getLocalAccessToken() 
        if(token){
            return  navigate('/')
        }
    })

    

    return (
        <form onSubmit={authenticate}>
        <Box 
            mt={{xs: 10, sm: 10, md: 10, lg: 10, xl: 20}}
            display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex"}}
            justifyContent="center"
            alignItems="center"
            maxWidth="100%">

                <Stack 
                    width="400px"
                    className={classes.container}
                    direction="column"
                    spacing={3}
                    justifyContent="center"
                    alignItems="center">

                        <Typography 
                            fontWeight="bold"
                            variant="h4"
                            component="div"
                            fontFamily="monospace">
                            
                                Login 
                        </Typography>

                        <TextField 
                            variant="outlined"
                            type="text"
                            label="Username"
                            onChange={handleUsernameInput}>

                        </TextField>


                        <TextField 
                        
                            variant="outlined"
                            type="password"
                            label="Password"
                            onChange={handlePasswordInput}>

                        </TextField>

                        <Stack 
                            width="50%"
                            direction="column">
                                <Button
                                    variant="contained"
                                    type="submit">
                                        Login 
                                </Button>
                        </Stack>


                       

                        <Stack 
                            direction="column"
                            spacing={1}> 
                            <Typography
                                fontFamily="monospace"
                                variant="p"
                                component="div">
                                    Don't have any account?
                            </Typography>

                            <Button component={Link} to={"/signup"}
                                sx={{ fontFamily: 'monospace'}}
                                variant="text"
                                >
                                    Sign up 
                            </Button>
                        </Stack>

                      

                </Stack>

        </Box>
        </form>
    )
}
export default Login 