import React, {useState} from 'react'
import Box from '@mui/material/Box'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined'
import SaveIcon from '@mui/icons-material/Save'
import PrintIcon from '@mui/icons-material/Print'
import ShareIcon from '@mui/icons-material/Share'


import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Link } from 'react-router-dom'

const pageHeight = window.innerHeight 

const actions = [
  { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
]

function MiniNav() {
  return (
    <Box sx={{ height: 3, transform: 'translateZ(0px)', flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="Mini Navigation"
        sx={{ position: 'fixed', zIndex: '-1', bottom: 0, right: 100, visibility: {xs: 'hidden', sm: 'hidden', md: 'visible', lg: 'visible'} }}
        icon={<SpeedDialIcon />}
      >
          <SpeedDialAction component={Link} to={"/cart"}
            icon={<ShoppingCartIcon/>}
            tooltipTitle="my cart"
          />
      </SpeedDial>
    </Box>
  )
}

export default MiniNav 
