import axios from 'axios' 


const getLocalAccessToken = () => {
    const accessToken = localStorage.getItem('access')
    return accessToken 
}

const getLocalRefreshToken = () => {
    const refreshToken = localStorage.getItem('refresh') 
    return refreshToken 
}

const auth_instance = axios.create({
    baseURL: 'https://backend.godlyvizion.com/', 
    headers: {
        'Content-Type': 'application/json',
    }, 
})

auth_instance.interceptors.request.use( (config) => {
    const token = getLocalAccessToken() 
    if(token){
        config.headers['Authorization'] = "Bearer " + token 
    }
    return config 
}, (error) => {
    return Promise.reject(error)
})


auth_instance.interceptors.response.use( (response) => {
    return response 
}, async (error) => {
    const originalConfig = error.config 
    if(error.response){
        if(error.response.status == 401 && !originalConfig._retry){
            originalConfig._retry = true 
            try{
                const rs = await refreshToken()
                const { access } = rs.data 
                localStorage.setItem('access', access)
                auth_instance.defaults.headers.common['Authorization'] = "Bearer " + access 
                return auth_instance(originalConfig) 
            }catch(_error){
                if(_error.response && _error.response.data){
                    return Promise.reject(_error.response.data)
                }
                return Promise.reject(_error)
            }
        }

        if(error.response.status == 403 && error.response.data){
            return Promise.reject(error.response.data) 
        }
    }

    return Promise.reject(error)


})

const refreshToken = async () => {
    return await auth_instance.post('api/refresh/', {
        refresh: getLocalRefreshToken(), 
    })
}


const signin = async (credentials) => {
    return await auth_instance.post('api/token/', credentials)
}

const getUsers = async () => {
    const data = await auth_instance.get('core/allusers/')
    return data.data 
}

const createUser = async (credentials) => {
    const data = await auth_instance.post('user/GodlyUser/', credentials)
    return data.data 
}


const createProfile = async (profile_info) => {
    return await auth_instance.post('user/GodlyUserProfile/', profile_info)
}

const createShippingAddress = async (shipping_address) => {
    return await auth_instance.post('user/ShippingAddress/', shipping_address)
}

const createPaymentMethod = async (payment_info) => {
    return await auth_instance.post('user/PaymentMethod/', payment_info)
}


const createProduct = async (product_info) => {
    return await auth_instance.post('products/products/', product_info)
}

const retrieveUserInfo = async () => {
    return await auth_instance.get('user/userinfo/')
}

const retrieveUserProfile = async () => {
    return await auth_instance.get('user/userprofileinfo/')
}

const retrieveUserPaymentMethod = async () => {
    return await auth_instance.get('user/userpaymentmethod/')
}

const retrieveUserShippingAddress = async () => {
    return await auth_instance.get('user/usershippingaddress/')
}

// edits | patches
const editShippingAddress = async (id, data) => {
    return await auth_instance.patch(`user/ShippingAddress/${id}/`, data)
}

const makeShippingActive = async (id, status) => {
    let data = {is_active: status}
    return await auth_instance.patch(`user/ShippingAddress/${id}/`, data)
}

const editEmailAddress = async (id, new_email) => {

    let data = {email: new_email} 
    return await auth_instance.patch(`user/GodlyUser/${id}/`, data) 
}

const editPhoneNumber = async (id, new_phone) => {
    let data = {phone_number: new_phone} 
    return await auth_instance.patch(`user/GodlyUserProfile/${id}/`, data)
}

const getSpecificShippingAddressDetail = async (id) => {
    return await auth_instance.get(`user/ShippingAddress/${id}/`) 
}

const deleteShippingAddress = async (id) => {
    return await auth_instance.delete(`user/ShippingAddress/${id}/`) 
}



//store backend axios 

const getMarketProducts = async () => {
    return await auth_instance.get('store/storeproducts/')
}

const getMarketProductDetail = async (id) => {
    return await auth_instance.get(`products/products/${id}/`)
}

const postCart = async (product) => {
    return await auth_instance.post('store/cartproducts/', product) 
}

const getCart = async () => {
    return await auth_instance.get('store/usercart/')
}

const flushCartAfterPurchase = async (id) => {
    return await  auth_instance.delete(`store/cartproducts/${id}/`, id)
}

const deleteFromCart = async (id) => {
    return await auth_instance.delete(`store/cartproducts/${id}/`, id)
}


// stripe connections 

const savePayment = async (data) => {
    return await auth_instance.post('store/stripe_payment/save', data) 
}


// save transactions info 

const saveTransaction = async (data) => {
    return await auth_instance.post('store/transactions/', data) 
}

const getCustomerOrders = async () => {
    return await auth_instance.get('user/myorders/')
}

const getCustomerTransaction = async () => {
    return await auth_instance.get('user/usertransaction/')
}

const getCustomerTransactionById = async (id) => {
    return await auth_instance.get(`store/transactions/${id}/`)
}



export {
    signin, 
    getLocalAccessToken, 
    getUsers,
    createUser,
    createProfile,  
    createShippingAddress, 
    createPaymentMethod, 
    createProduct, 
    retrieveUserInfo, 
    retrieveUserProfile, 
    retrieveUserPaymentMethod, 
    retrieveUserShippingAddress, 
    editShippingAddress, 
    getSpecificShippingAddressDetail,
    deleteShippingAddress,
    makeShippingActive,
    editEmailAddress, 
    editPhoneNumber, 
    getMarketProducts, 
    getMarketProductDetail, 
    postCart, 
    getCart, 
    flushCartAfterPurchase, 
    deleteFromCart, 
    savePayment, 
    saveTransaction, 
    getCustomerOrders, 
    getCustomerTransaction, 
    getCustomerTransactionById,

    
}