import React, {useState, useEffect} from 'react' 

import { Box, Stack } from '@mui/material'

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'

import MiniNav from './MiniNav'
import About from './About'
import Privacy from './Privacy'
import Refund from './Refund' 
import Shipping from './Shipping'

function AppFooter() {

    const [aboutStatus, setAboutStatus] = useState(false)
    const [privacyStatus, setPrivacyStatus] = useState(false)
    const [refundStatus, setRefundStatus] = useState(false)
    const [shippingStatus, setShippingStatus] = useState(false) 


    const aboutHandler = () => {
        setAboutStatus(false)
    }

    const privacyHandler = () => {
        setPrivacyStatus(false)
    }

    const refundHandler = () => {
        setRefundStatus(false)
    }

    const shippingHandler = () => {
        setShippingStatus(false) 
    }

    return (
        <Box 
            justifyContent="center" 
            alignItems="center"
            mt={5}
            position="bottom"
            display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex"}}
            maxWidth="100%">

                <Stack 
                    direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row'}}
                    spacing={3}>
                        <Button 
                            sx={{ fontFamily: 'monospace'}}
                            variant="text"
                            onClick={() => ( setAboutStatus(true))}>
                                @ godlyvision,  LLC 
                        </Button>

                        <Button 
                            sx={{ fontFamily: 'monospace'}}
                            variant="text"
                            onClick={() => ( setPrivacyStatus(true))}>
                                Privacy Info  
                        </Button>

                        <Button
                            sx={{ fontFamily: 'monospace'}}
                            variant="text"
                            onClick={() => ( setRefundStatus(true))}>
                                Refund Policy 
                        </Button>

                        <Button 
                            sx={{ fontFamily: 'monospace'}}
                            variant="text"
                            onClick={() => ( setShippingStatus(true))}>
                                Shipping Info  
                        </Button>


                </Stack>


                <About status={aboutStatus} handler={aboutHandler}/>
                <Shipping status={shippingStatus} handler={shippingHandler}/>
                <Privacy status={privacyStatus} handler={privacyHandler}/>
                <Refund status={refundStatus} handler={refundHandler}/>

        </Box>
    )
}

export default AppFooter 