

import React from 'react' 
import Icon from '@mui/material/Icon'

import logo from '../logo/logo512.png'

function AppIcon() {

    return (
        <Icon
            fontSize="large">
            <img width="50px" height="50px" src={logo}/>
        </Icon>
    )

}

export default AppIcon 