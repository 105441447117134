
import React, {useState, useEffect} from 'react' 

import Box from '@mui/material/Box' 
import Stack from '@mui/material/Stack' 


import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip' 

import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'


import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import HomeIcon from '@mui/icons-material/Home'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide';

import {Link, Navigate, useNavigate}  from 'react-router-dom' 

import { makeStyles } from '@mui/styles'
import { getLocalAccessToken, getCart, deleteFromCart } from '../Session/AuthSession'
import { retrieveUserShippingAddress, flushCartAfterPurchase, saveTransaction} from '../Session/AuthSession'


import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'



import Message from '../Components/Message' 

import StripePaymentForm  from '../Components/StripeCore'

//braintree 

import dropin from 'braintree-web-drop-in'


const useStyles = makeStyles({
    container: {

        boxShadow: '4px 10px 16px 1px rgba(4,4,4,.3)',
        backgroundColor: 'white',
    
   }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

  const shippingData = {
    full_shipping_address: "",
    shipping_address: "Select Address",
    shipping_city: "", 
    shipping_country: "",
    shipping_state: "",
  }

function Cart(){

    const classes = useStyles() 
    const navigate = useNavigate() 

    //message alert 
    const [messageInfo, setMessageInfo] = useState() 
    const [messageIndicator, setMessageIndicator] = useState() 
    const [messageStatus, setMessageStatus] = useState(false) 

    const handleMessageStatus = () => {
        setMessageStatus(false) 
    }


    const [userCart, updateUserCart] = useState([])

    /* Checkout */



    //shippingAddress
    const [shippingAddress, setShippingAddress] = useState(shippingData) 
    const [shippingAddressList, setShippingAddressList] = useState([])
    const [shippingDialog, setShippingDialog] = useState(false) 


    //email address
    const [shippingEmail, setShippingEmail] = useState()


    //payment methods 
    const [paymentMethodDialog, setPaymentMethodDialog] = useState(false)
    const handlePaymentDialog = () => {
        setPaymentMethodDialog(false) 
    }


    //configs
    const [paymentSuccess, setPaymentSuccess] = useState(false)
    const [orderID, setOrderID] = useState(false) 
    const [totalPrice, setTotalPrice] = useState() 
    const [paypalButtonStatus, setPaypalButtonStatus] = useState(false)


    const getCartTotal = () => {
        
        let total_price = 0 
        userCart.map((product) => (
            total_price += parseFloat(product.product_price)
        ))
        
        return total_price.toFixed(2)
    }


    const getCartTotalForPurchase = () => {
        
        let total_price = 0 
        userCart.map((product) => (
            total_price += parseFloat(product.product_price)
        ))
        
        return total_price.toFixed(2) * 100
    }



    const proceedCheckout = () => {
        setTotalPrice(getCartTotalForPurchase()) 
        setPaypalButtonStatus(true)

    }

    

    const createOrder = (data, actions) => {

        
        return actions.order.create({
            purchase_units: [
                {
                    description: "Cart Info",
                    amount: {
                        currency_code: 'USD',
                        value: parseInt(totalPrice)
                    },

                    payee: {
                        email_address: shippingEmail,
                    },

                    

                },
            ],


            
        }).then((orderID) => {
            setOrderID(orderID)
           
            return orderID 
        })
    }


    // check approval 

    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const {payer}  = details 
            setPaymentSuccess(true)
        })
    }

    //check Errors 
    const onError = (data, actions) => {
        setMessageInfo("En Error occured with you payment")
    }










    const handleShippingDialog = () => {
        setShippingDialog(false) 
    }

    const setupShipping = async () => {
        setShippingDialog(true) 
        const data = await retrieveUserShippingAddress()
                    .then((res) => {
                        setShippingAddressList(res.data)
                    })
    }


    const removeFromCart = async (id) => {
        await deleteFromCart(id)
            .then(() => {
                setMessageInfo('Removed')
                setMessageIndicator('success')
                setMessageStatus(true)
            }).catch(() => {
                setMessageInfo('Error')
                setMessageIndicator('error')
                setMessageStatus(true)
            })

        refreshUserCart() 

    }



    const refreshUserCart = async () => {

        return await getCart()
                     .then((res) => {
                         updateUserCart(res.data[0].cart_products)
                         setShippingEmail(res.data[0].owner)

                     })
                     .catch((error) => {
                         console.error(error)
                     })
    }




   useEffect(() => {
       const token = getLocalAccessToken() 
       if(!token){
           return navigate('/')
       }

       


      

       const paymentCompleted = async () => {
            if (paymentSuccess){
                setMessageInfo("Purchased through Paypal")
                setMessageIndicator("success")
                setMessageStatus(true)
                userCart.map((item) => {
                    flushCartAfterPurchase(item.id)
                })
            }

       }


       const mountUserCart = async () => {

           return await getCart()
                        .then((res) => {
                            updateUserCart(res.data[0].cart_products)
                            setShippingEmail(res.data[0].owner)

                        })
                        .catch((error) => {
                            console.error(error)
                        })
       }

       mountUserCart()
       paymentCompleted() 

   }, [paymentSuccess])


 
console.log('UserCart', userCart)



    return (
        <Box 
            mt={{xs: 10, sm: 10, md: 10, lg: 10, xl: 20}}
            display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex"}}
            justifyContent="center"
            alignItems="center"
            maxWidth="100%"
            >

                <Stack 
                    
                    direction="column"
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    maxWidth="100%"
                    >

                        <Typography 
                            fontWeight="bold"
                            variant="h4"
                            component="div"
                            fontFamily="monospace">
                            
                                My Cart 
                        </Typography>



                        <Stack 
                            direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row'}}
                            spacing={10}
                          
                           >


                            {/**Cart Items */}

                        <Stack 
                            direction="column"
                            spacing={3}
                            alignItems="center"
                            justifyContent="center"
                           >

                                {userCart.map(( product, key) => (

                                    <Card 
                                        key={key} 
                                        sx={{ boxShadow: 1, width: {xs: '80%'}}}
                                        >
                                            
                                        <CardMedia 
                                            sx={{height: '20rem'}}
                                            component="img"
                                            image={product.product_images[0].url}
                                            alt={product.name}/>

                                        <CardContent>
                                            <Stack 
                                                direction="row"
                                                spacing={3}
                                                alignItems="center"
                                                justifyContent="center">

                                                 <Stack 
                                                    direction="column"
                                                    spacing={1}>

                                                    <Typography 
                                                        fontFamily="monospace" 
                                                        variant="h6"
                                                        component="div" 
                                                        >
                                                            $ {product.product_price}
                                                    </Typography>

                                                    <Typography
                                                        fontFamily="monospace" 
                                                        variant="subtitle2"
                                                        component="div" 
                                                        >
                                                            {product.name}
                                                    </Typography>
                                                </Stack>

                                                <Stack 
                                                    direction="column"
                                                    spacing={3}>

                                                        <Button 
                                                            variant="contained"
                                                            color="error"
                                                            startIcon={<DeleteOutlineIcon/>}
                                                            onClick={() => (
                                                                removeFromCart(product.id)
                                                            )}>
                                                            Remove Item 
                                                        </Button>
                                                </Stack>
                                            </Stack>
                                        </CardContent>

                                    </Card>

                                ))}

                        </Stack>

                        {/**Action Panel */}


                        <Stack 
                            direction="column"
                            spacing={3}
                            alignItems="top"
                            justifyContent="top">

                                <Stack 
                                    direction="row"
                                    spacing={3}
                                    alignItems="center"
                                    justifyContent="center" >

                                    <Typography
                                        fontFamily="monospace" 
                                        variant="h6"
                                        component="div">
                                            Total:  
                                    </Typography>

                                    <Typography 
                                        fontFamily="monospace"
                                        variant="h5"
                                        component="div">
                                            $ {getCartTotal()} 
                                    </Typography>

                                   
                                </Stack>

                                <Stack 
                                    direction="column"
                                    spacing={3}
                                    alignItems="center"
                                    justifyContent="center" 
                                    >

                                       
                                        <Chip
                                            variant="outlined"
                                            size="medium"
                                            color="primary" 
                                            label={shippingEmail}>

                                            </Chip>

                                        <Button 
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<HomeIcon/>}
                                            onClick={() => (
                                                setupShipping() 
                                            )}
                                            >
                                            {shippingAddress.shipping_address}
                                        </Button>


                                        <Button 
                                            variant="contained"
                                            sx={{ backgroundColor: 'purple'}}
                                            disabled
                                            onClick={() => (
                                                proceedCheckout() 
                                            )}>
                                                Checkout with Paypal 
                                        </Button>

                                        <Chip 
                                            variant="outlined"
                                            size="small"
                                            color="info"
                                            label="Paypal under construction"/>

                                        <Button 
                                            variant="contained"
                                            sx={{ backgroundColor: 'purple'}}
                                            endIcon={<CreditCardIcon/>}
                                            onClick={() => (
                                                setPaymentMethodDialog(true), 
                                                setTotalPrice(getCartTotalForPurchase())
                                            )}
                                            >
                                                Checkout with Card 
                                        </Button>

                                        {paypalButtonStatus &&
                                        <PayPalScriptProvider options={{ "client-id": "AWlVu0uRRvaEbVOP6B41eNCmjTyVnKJYF4r5RN7zxFW-xMmAmtMr-S5nUfsyYjqnPDTjbK2uyhvO_ht_"}}>
                                            <PayPalButtons 
                                                createOrder={createOrder}
                                                onApprove={onApprove}
                                                style={{ layout: "horizontal"}}/>
                                        </PayPalScriptProvider>
                                        }



                                         {/** 
                                        <form onSubmit={handleCheckout}>
                                            <Button 
                                                variant="contained"
                                                color="success"
                                                type="submit"
                                                >
                                                    checkout 
                                            </Button>
                                        </form>
                                        */}


                                    </Stack>
                        </Stack>



                        </Stack> 


                </Stack>

            {/**Shipping Dialog */}
                <Dialog
                    open={shippingDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleShippingDialog}
                    aria-describedby="shipping-dialog"
                >
                    <DialogTitle>{"Select Shipping Address"}</DialogTitle>
                    <DialogContent>
                            
                           <Stack 
                                direction="column"
                                spacing={3}
                                alignItems="center"
                                justifyContent="center">

                                {shippingAddressList.map((address, key) => (
                                    <Button 
                                        key={key}
                                        variant="outlined"
                                        onClick={() => (
                                            setShippingAddress({
                                                full_shipping_address: address.full_shipping_address, 
                                                shipping_address: address.shipping_address,
                                                shipping_city: address.shipping_city, 
                                                shipping_country: address.shipping_country,
                                                shipping_state: address.shipping_state, 
                                            }), 


                                            setMessageInfo("Address Selected"), 
                                            setMessageIndicator("success"),
                                            setMessageStatus(true),
                                            setShippingDialog(false) 
                                            
                                        )}
                                        >
                                         {address.shipping_address}, {address.shipping_country},  {address.shipping_city}, {address.shipping_state}
                                    </Button>
                                ))}
                            </Stack>
                    </DialogContent>
                    
            </Dialog>

            {/**Payment Method Dialog */}

            <Dialog
                    open={paymentMethodDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handlePaymentDialog}
                    aria-describedby="payment-method-dialog"
                    fullWidth 
                >
                    
                    <DialogContent
                        sx={{ backgroundColor: 'black'}}
                        >

                            <StripePaymentForm total={parseInt(totalPrice)} transaction_price={getCartTotal()} shipping_address={shippingAddress.shipping_address} orders={userCart}/>
                    </DialogContent>
                    
            </Dialog>

            <Message messageStatus={messageStatus} message={messageInfo} messageIndicator={messageIndicator} messageClose={handleMessageStatus}/>


        </Box>

    )
}


const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        minWidth: '100%',
        ":-webkit-autofill": {
          color: "#fce883"
        },
        "::placeholder": {
          color: "#87bbfd"
        }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee"
      }
    }
  };


export default Cart