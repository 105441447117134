
import React, {useState, useEffect, forwardRef, useImperativeHandle } from 'react' 

import Box from '@mui/material/Box' 
import Stack from '@mui/material/Stack' 


import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'


import {Link, Navigate, useNavigate}  from 'react-router-dom' 

import { makeStyles } from '@mui/styles'


import { getLocalAccessToken, retrieveUserInfo, retrieveUserProfile } from '../Session/AuthSession'
import { createShippingAddress, retrieveUserShippingAddress } from '../Session/AuthSession'
import { editShippingAddress, getSpecificShippingAddressDetail, deleteShippingAddress} from '../Session/AuthSession' 
import { editEmailAddress, editPhoneNumber , getCustomerOrders, getCustomerTransaction, getCustomerTransactionById } from '../Session/AuthSession' 



import { Alert, Card, CardContent, CardMedia, Chip, DialogActions, DialogContentText, DialogTitle, InputLabel, MenuItem, Select } from '@mui/material'
import { FormControl } from '@mui/material'

import Dialog from '@mui/material/Dialog'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { DialogContent } from '@mui/material'
import Snackbar from '@mui/material/Snackbar' 


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})


const useStyles = makeStyles({
    container: {

        boxShadow: '4px 10px 16px 1px rgba(4,4,4,.3)',
        backgroundColor: 'white',
    
   }
})


const states = 
    ['Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'IllinoisIndiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'MontanaNebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'PennsylvaniaRhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
    ]




function  Profile(){

    const classes = useStyles() 
    const navigate = useNavigate() 

    const [isLogged, setLogged] = useState(true)
    const [profileID, setProfileID] = useState()
    const [userID, setUserID] = useState() 
    const token = getLocalAccessToken() 

    //dialogs 
    const [openShippingInfo, updateOpenShippingInfo] = useState(false) 
    const [openPaymentInfo, updateOpenPaymentInfo] = useState(false) 
    const [createShipping, updateCreateShipping] = useState(false) 
    const [editShipping, updateEditShipping] = useState(false) 
    const [deleteShipping, updateDeleteShipping] = useState(false) 
    const [viewOrder, updateViewOrder] = useState(false) 
    const [viewTransaction, updateTransaction] = useState(false) 


    // view orders 
    const [recentOrders, setRecentOrders] = useState([])
    const [recentTransactions, setRecentTransactions]  = useState([]) 


    const handleViewOrders = () => {
        updateViewOrder(false)
    }

    const handleViewTransaction = () => {
        updateTransaction(false) 
    }

    const handleShippingInfo = () => {
        updateOpenShippingInfo(false) 
    }

    const [fullAddressStatus, setFullAddressStatus] = useState(false) 
    const handleFullAddressStatus = () => {
        setFullAddressStatus(false) 
    }

    

    //errorinfo 
    const [errorInfoStatus, setErrorInfoStatus] = useState(false)
    const [errorMessage, setErrorMessage] = useState() 
    const [errorColor, setErrorColor] = useState()


    const handleErrorInfo = () => {
        setErrorInfoStatus(false) 
    }



    //profile info 
    const [userdata, setUserData] = useState([])
    const [profiledata, setProfileData] = useState([])

    //patch info 

    //email
    const [newEmail, setNewEmail] = useState()
    const [newEmailDialog, setNewEmailDialog] = useState(false) 
    const handleNewEmail = (event) => {
        setNewEmail(event.target.value) 
    }
    const handleNewEmailDialog = () => {
        setNewEmailDialog(false) 
    }

    //phone 
    const [newPhone, setNewPhone] = useState() 
    const [newPhoneDialog, setNewPhoneDialog] = useState(false) 
    const handleNewPhone = (event) => {
        setNewPhone(event.target.value)
    }
    const handleNewPhoneDialog = () => {
        setNewPhoneDialog(false) 
    }



    //shipping info 
    const [shippingInfo, setShippingInfo] = useState([])
    const [fullShippingDetail, setFullShippingDetail] = useState() 
    const [shippingID, setShippingID] = useState() 
    
    //payment methods 
    const [paymentMethods, setPaymentMethods] = useState([]) 


    //create new shipping 

    const [fullShippingAddress, setFullShippingAddress] = useState()
    const [shippingAddress, setShippingAddress] = useState() 
    const [shippingCity, setShippingCity] = useState() 
    const [shippingCountry, setShippingCountry] = useState() 
    const [shippingState, setShippingState] = useState() 


    const handleFullShippingInput = (event) => {
        setFullShippingAddress(event.target.value)
    }
    const handleShippingInput = (event) => {
        setShippingAddress(event.target.value)
    }
    const handleShippingCity = (event) => {
        setShippingCity(event.target.value)
    }
    const handleShippingCountry = (event) => {
        setShippingCountry(event.target.value)
    }
    const handleShippingState = (event) => {
        setShippingState(event.target.value) 
    }

    //edit shipping info 

    const [fullShippingAddressEdit, setFullShippingAddressEdit] = useState()
    const [shippingAddressEdit, setShippingAddressEdit] = useState() 
    const [shippingCityEdit, setShippingCityEdit] = useState() 
    const [shippingCountryEdit, setShippingCountryEdit] = useState() 
    const [shippingStateEdit, setShippingStateEdit] = useState() 

    const [shippingPostData, setShippingPostData] = useState({})    
    
    const handleFullShippingEdit = (event) => {
        setFullShippingAddressEdit(event.target.value)
    }
    const handleShippingEdit = (event) => {
        setShippingAddressEdit(event.target.value)
    }
    const handleShippingCityEdit = (event) => {
        setShippingCityEdit(event.target.value)
    }
    const handleShippingCountryEdit = (event) => {
        setShippingCountryEdit(event.target.value)
    }
    const handleShippingStateEdit = (event) => {
        setShippingStateEdit(event.target.value) 
    }


    const handleCreateShipping = () => {
        updateCreateShipping(false) 
    }

    const handleEditShipping = () => {
        updateEditShipping(false) 
    }

    const handleDeleteShipping = () => {
        updateDeleteShipping(false) 
    }

    const changeEmail = async (event) => {
        event.preventDefault() 
        return await editEmailAddress(userID, newEmail)
                .then(() => {
                    refreshProfile() 
                    handleNewEmailDialog(false) 
                    setErrorMessage('Email Updated!')
                    setErrorColor('success')
                    setErrorInfoStatus(true)

                })
                .catch(() => {
                    setErrorMessage('Error Whiling Updating Email')
                    setErrorColor('error')
                    setErrorInfoStatus(true)
                })
    }


    const changePhone = async (event) => {
        event.preventDefault() 
        return await editPhoneNumber(profileID, newPhone)
                .then(() => {
                    refreshProfile() 
                    handleNewPhoneDialog(false) 
                    setErrorMessage('Phone Updated!')
                    setErrorColor('success')
                    setErrorInfoStatus(true)

                })
                .catch(() => {
                    setErrorMessage('Error Whiling Updating Phone')
                    setErrorColor('error')
                    setErrorInfoStatus(true)
                })
    }

    const submitShipping = async (event) => {
        event.preventDefault() 

        if(shippingAddress == null || shippingCity == null || shippingCountry == null || shippingState == null){
            setErrorMessage('Fill out form!')
            setErrorColor("error")
            setErrorInfoStatus(true) 
            return 
        }

        let data = {
            shipping_country: shippingCountry, 
            shipping_state: shippingState, 
            shipping_city: shippingCity, 
            shipping_address: shippingAddress, 
            full_shipping_address: fullShippingAddress,
        }

        return await createShippingAddress(data)
                    .then(() => {
                        updateCreateShipping(false)
                        setErrorMessage('Shipping Info Created.')
                        setErrorColor("success")
                        setErrorInfoStatus(true)
                        getShippingAddresses() 

                    }).catch((error) => {
                        setErrorMessage('Error while Creating...')
                        setErrorColor("error")
                        setErrorInfoStatus(true)
                        console.error(error) 
                    })
    }


    const submitEditShipping = async (event) => {
        event.preventDefault() 

        if(shippingAddressEdit == null || shippingCityEdit == null || shippingCountryEdit == null || shippingStateEdit == null){
            setErrorMessage('Fill out form!')
            setErrorColor("error")
            setErrorInfoStatus(true) 
            return 
        }

        let data = {
            shipping_country: shippingCountryEdit, 
            shipping_state: shippingStateEdit, 
            shipping_city: shippingCityEdit, 
            shipping_address: shippingAddressEdit, 
            full_shipping_address: fullShippingAddressEdit,
        }

        return await editShippingAddress(shippingID, data)
                    .then(() => {
                        updateEditShipping(false)
                        setErrorMessage('Shipping Info Updated.')
                        setErrorColor("success")
                        setErrorInfoStatus(true)
                        getShippingAddresses() 

                    }).catch((error) => {
                        setErrorMessage('Error while Updating..')
                        setErrorColor("error")
                        setErrorInfoStatus(true)
                        console.error(error) 
                    })
    }


    const submitDeleteShipping = async (id) =>{
        return await deleteShippingAddress(id)
                .then(() => {
                    getShippingAddresses() 
                    updateDeleteShipping(false) 
                    setErrorMessage('Shipping Info Deleted')
                    setErrorColor("success")
                    setErrorInfoStatus(true)
                })
                .catch((error) => {
                    setErrorMessage('Error while Deleting')
                    setErrorColor("error")
                    setErrorInfoStatus(true)
                    console.error(error)
                })
    }




    const getShippingAddresses = async () => {
        const data = await retrieveUserShippingAddress()
                    .then((res) => {
                        setShippingInfo(res.data) 
                    })
                    .catch((error) => {
                        console.error(error) 
                    })
    }


    const logout = () => {
        localStorage.removeItem('refresh')
        localStorage.removeItem('access')
        navigate('/')
    }

    const refreshProfile = async () => {
        const data = await retrieveUserInfo()
                    .then((res) => {
                        setUserData(res.data)
                    })
                    .catch((error) => {
                        console.error(error) 
                    })
        
        const another_data = await retrieveUserProfile()
                            .then((res) => {
                                setProfileData(res.data)
                            })
                            .catch((error) => {
                                console.error(error)
                            })
    }

    const mountSpecificTransaction = async (id) => {
        const data = await getCustomerTransactionById(id)
                    .then((res) => {
                        setRecentOrders(res.data.user_transaction)
                    }).catch((error) => {
                        console.error(error)
                    })
    } 



    useEffect(() => {
        if(!token){
            return navigate('/')
        }

        const mountUserInfo = async () => {
            const data = await retrieveUserInfo()
                        .then((res) => {
                            setUserData(res.data)
                        })
                        .catch((error) => {
                            console.error(error)
                        })
        }

        const mountUserProfile = async () => {
            const data = await retrieveUserProfile()
                         .then((res) => {
                             setProfileData(res.data)
                         })
                         .catch((error) => {
                             console.error(error) 
                         })
        }

        const mountUserTransactions = async () => {
            const data = await getCustomerTransaction()
                        .then((res) => {
                            setRecentTransactions(res.data)
                        }).catch((error) => {
                            console.error(error) 
                        })
        }

        



       
        

        mountUserInfo() 
        mountUserProfile() 
        mountUserTransactions() 

    }, [])

    console.log('Recent Order: ', recentOrders)



    return (
            <Box 
                mt={{xs: 10, sm: 10, md: 10, lg: 10, xl: 20}}
                display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex"}}
                justifyContent="center"
                alignItems="center"
                maxWidth="100%">

                    
                


                    <Stack
                        maxWidth="100%"
                        direction="column"
                        spacing={5}>


                        <Stack 
                            maxWidth="100%"
                            className={{md: classes.container, lg: classes.container, xl: classes.container}}
                            direction="column"
                            spacing={3}
                            justifyContent="center"
                            alignItems="center">

                            <Typography 
                                fontWeight="bold"
                                variant="h4"
                                component="div"
                                fontFamily="monospace">
                                
                                    My Profile 
                            </Typography>

                            <Stack 
                                direction="row"
                                spacing={3}>



                                <Stack 
                                    direction="column"
                                    spacing={3}>

                                        
                                            <Chip 
                                                variant="outlined"
                                                size="medium"
                                                color="info"
                                                label="Username "/>

                                            <Chip 
                                                variant="outlined"
                                                size="medium"
                                                color="info"
                                                label="Email "/>

                                            <Chip 
                                                variant="outlined"
                                                size="medium"
                                                color="info"
                                                label="Phone "/>
                                            {/** 
                                            <Chip 
                                                variant="outlined"
                                                size="medium"
                                                color="info"
                                                label="Shipping"/>
                                            */}

                                            <Chip 
                                                variant="outlined"
                                                size="medium"
                                                color="info"
                                                label="Shipping"/>

                                            <Chip 
                                                variant="outlined"
                                                size="medium"
                                                color="info"
                                                label="Orders"/>
                                        
                                </Stack>


                                    <Stack 
                                        direction="column"
                                        spacing={3}>
                                            {userdata.map((data, key) => (


                                                <Stack
                                                    key={key}
                                                    direction="column"
                                                    spacing={3}>

                                                    <Chip 
                                                        variant="outlined"
                                                        size="medium"
                                                        color="secondary"
                                                        label={data.username}/>

                                                    <Stack 
                                                        direction="row"
                                                        spacing={2}>

                                                        <Chip 
                                                            variant="outlined"
                                                            size="medium"
                                                            color="secondary"
                                                            label={data.email}>
                                                                
                                                        </Chip>

                                                        <Button
                                                            variant="text"
                                                            sx={{ fontFamily: 'monospace', color: 'purple'}}
                                                            onClick={()=>(
                                                                setNewEmailDialog(true),
                                                                setUserID(data.id) 
                                                            )}>
                                                                Edit 
                                                        </Button>

                                                    </Stack>
                                                
                                                
                                                </Stack>
                                            ))}

                                            {profiledata.map((data, key) => (
                                                <Stack 
                                                    key={key}
                                                    direction="column"
                                                    spacing={3}>

                                                    
                                                    <Stack 
                                                        direction="row" 
                                                        spacing={2}>
                                                    

                                                        {data.phone_number == null ? (
                                                            <Chip 
                                                                variant="outlined"
                                                                size="medium"
                                                                color="secondary"
                                                                label="No Phone Number"/>
                                                        ) :(
                                                            <Chip 
                                                                variant="outlined"
                                                                size="medium"
                                                                color="secondary"
                                                                label={data.phone_number}/>
                                                        )}

                                                        <Button 
                                                            variant="text" 
                                                            sx={{ fontFamily: 'monospace', color: 'purple'}}
                                                            onClick={() => (
                                                                setNewPhoneDialog(true),
                                                                setProfileID(data.id) 
                                                            )}
                                                            >
                                                                Edit 
                                                            </Button>
                                                    </Stack>

                                                        <Button 
                                                            variant="contained"
                                                            onClick={() => (
                                                                updateOpenShippingInfo(true), getShippingAddresses()
                                                            )}
                                                            >
                                                                View Shipping Info 
                                                        </Button>
                                                    {/** 

                                                        <Button 
                                                            variant="contained"
                                                            onClick={() => (
                                                                setPaymentMethods(data.payment_methods), updateOpenPaymentInfo(true)
                                                            )}
                                                        >
                                                            View Payment Methods 
                                                        </Button>

                                                    */}
                                                        <Button 
                                                            variant="contained"
                                                            sx={{ fontFamily: 'monospace',background: 'purple', color: 'white'}}
                                                            onClick={() => (
                                                                updateTransaction(true)
                                                            )}
                                                            >
                                                                View Orders 
                                                            </Button>
                                                   

                                                        

                                                </Stack>
                                            ))}

                                            

                                    </Stack>



                            </Stack>


                            <Button 
                                variant="text"
                                color="error"
                                onClick={logout}
                                >
                                    Logout
                            </Button>

                        </Stack>
                    
                    </Stack>

                    {/** Edit Email Address */}
                            <Dialog
                                open={newEmailDialog}
                                onClose={handleNewEmailDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                {"Change Email Address"}
                                </DialogTitle>
                                <DialogContent>
                                
                                <form onSubmit={changeEmail}>
                                    <Stack 
                                        direction="column"
                                        spacing={3}>

                                            <TextField 
                                                variant="outlined"
                                                label="New Email Address"
                                                type="email" 
                                                onChange={handleNewEmail}/>

                                            <Button 
                                                variant="contained"
                                                sx={{ fontFamily: 'monospace',background: 'purple', color: 'white'}}

                                                type="submit">
                                                    Update 
                                                </Button>

                                    </Stack>
                                </form>
                                
                                </DialogContent>
                                <DialogActions>
                                
                                <Button onClick={handleNewEmailDialog} 

                                    autoFocus>
                                    Close 
                                </Button>
                                </DialogActions>
                            </Dialog>

                    {/** Edit New Phone */}

                             <Dialog
                                open={newPhoneDialog}
                                onClose={handleNewPhoneDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                {"Change Phone Number"}
                                </DialogTitle>
                                <DialogContent>
                                
                                <form onSubmit={changePhone}>
                                    <Stack 
                                        direction="column"
                                        spacing={3}>

                                            <TextField 
                                                variant="outlined"
                                                label="New Phone Number"
                                                type="tel" 
                                                onChange={handleNewPhone}/>

                                            <Button 
                                                variant="contained"
                                                sx={{ fontFamily: 'monospace',background: 'purple', color: 'white'}}
                                                type="submit">
                                                    Update 
                                            </Button>

                                    </Stack>
                                </form>
                                
                                </DialogContent>
                                <DialogActions>
                                
                                <Button onClick={handleNewPhoneDialog} autoFocus>
                                    Close 
                                </Button>
                                </DialogActions>
                            </Dialog>


                    {/** Shipping Info Dialog */}
                    <Dialog
                        fullScreen
                        open={openShippingInfo}
                        onClose={handleShippingInfo}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleShippingInfo}
                            aria-label="close"
                            >
                            <CloseIcon />
                            </IconButton>

                        </Toolbar>
                        </AppBar>


                        <DialogContent>

                                <Stack 
                                    alignItems="center"
                                    justifyContent="center"
                                    direction="column"
                                    maxWidth="100%"
                                    spacing={5}>

                                        <Stack 
                                            maxWidth="100%"
                                            direction="column"
                                            spacing={3}>

                                                <Button 
                                                    variant="contained"
                                                    color="success"
                                                    onClick={() => (
                                                        updateCreateShipping(true)
                                                    )}>
                                                        Create New Shipping 
                                                    </Button>

                                        </Stack>

                                    {shippingInfo.map((data, key) => (


                                        <Card

                                            key={key}
                                            
                                            sx={{ boxShadow: 3,}}>
                                            <CardContent>
                                                <Stack 
                                                    direction="column"
                                                    spacing={5}>

                                                    

                                                
                                                    <Stack 
                                                        alignItems = "center"
                                                        justifyContent="center"
                                                        direction="row"
                                                        
                                                        spacing={3}>
                                                        <Stack 
                                                            direction="column"
                                                            spacing={3}>
                                                                <Chip 
                                                                    variant="outlined"
                                                                    size="medium"
                                                                    color="info"
                                                                    label="Country">

                                                                </Chip>

                                                                <Chip 
                                                                    variant="outlined"
                                                                    size="medium"
                                                                    color="info"
                                                                    label="Address">

                                                                </Chip>

                                                                <Chip 
                                                                    variant="outlined"
                                                                    size="medium"
                                                                    color="info"
                                                                    label="State">

                                                                </Chip>

                                                                <Chip 
                                                                    variant="outlined"
                                                                    size="medium"
                                                                    color="info"
                                                                    label="City">

                                                                </Chip>
                                                                
                                                                <Chip 
                                                                    variant="outlined"
                                                                    size="medium"
                                                                    color="info"
                                                                    label="Full Address">

                                                                </Chip>


                                                        </Stack>

                                                        <Stack 
                                                            direction="column"
                                                            spacing={3}>
                                                                <Chip 
                                                                    variant="outlined"
                                                                    size="medium"
                                                                    color="secondary"
                                                                    label={data.shipping_country}>

                                                                </Chip>

                                                                <Chip 
                                                                    variant="outlined"
                                                                    size="medium"
                                                                    color="secondary"
                                                                    label={data.shipping_address}>

                                                                </Chip>

                                                                <Chip 
                                                                    variant="outlined"
                                                                    size="medium"
                                                                    color="secondary"
                                                                    label={data.shipping_state}>

                                                                </Chip>

                                                                <Chip 
                                                                    variant="outlined"
                                                                    size="medium"
                                                                    color="secondary"
                                                                    label={data.shipping_city}>

                                                                </Chip>
                                                                
                                                                
                                                                <Button 
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    onClick={() => (
                                                                        setFullShippingDetail(data.full_shipping_address), setFullAddressStatus(true)
                                                                    )}>
                                                                        View 
                                                                </Button>


                                                        </Stack>

                                                    </Stack>

                                                    <Stack 
                                                        alignItems="center" 
                                                        justifyContent="center" 
                                                        direction="row"
                                                        spacing={3}>

                                                                <Button 
                                                                    variant="contained"
                                                                    color="info"
                                                                    onClick={() => (
                                                                        setShippingID(data.id),updateEditShipping(true)
                                                                    )}>
                                                                        Edit
                                                                </Button>

                                                                <Button 
                                                                    variant="contained"
                                                                    color="error"
                                                                    onClick={() => (
                                                                        setShippingID(data.id), updateDeleteShipping(true)
                                                                    )}> 
                                                                        Delete
                                                                </Button>


                                                                
                                                    </Stack>
                                                </Stack>

                                                
                                            </CardContent>
                                        </Card>

                                    ))}

                                </Stack>
                        </DialogContent>

                            <Dialog
                                open={fullAddressStatus}
                                onClose={handleFullAddressStatus}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                {"Full Shipping Address"}
                                </DialogTitle>
                                <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {fullShippingDetail}
                                </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                
                                <Button onClick={handleFullAddressStatus} autoFocus>
                                    Close 
                                </Button>
                                </DialogActions>
                            </Dialog>
        
                    </Dialog>


                    {/** Add new Shipping Dialog */}

                    <Dialog
                        fullScreen
                        open={createShipping}
                        onClose={handleCreateShipping}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCreateShipping}
                            aria-label="close"
                            >
                            <CloseIcon />
                            </IconButton>

                        </Toolbar>
                        </AppBar>


                        <DialogContent>

                            <Stack 
                                alignItems="center" 
                                justifyContent="center"
                                direction="column"
                                spacing={3}>

                                <Typography 
                                    variant="h6"
                                    component="div"
                                    fontFamily="monospace">
                                        Create New Shipping Info 
                                </Typography>

                                <form onSubmit={submitShipping}>

                                <Stack 
                                    alignItems="center" 
                                    justifyContent="center"
                                    direction="column"
                                    spacing={3}>

                                        <FormControl fullWidth>

                                            <InputLabel id="country_label">Country</InputLabel>
                                            <Select
                                            labelId="country_label"
                                            id="country_select"
                                            value={shippingCountry}
                                            label="Country"
                                            onChange={handleShippingCountry}
                                            >
                                            <MenuItem value="United States">United States</MenuItem>
                                            </Select>

                                        </FormControl>


                                        <FormControl fullWidth>

                                            <InputLabel id="state_label">State</InputLabel>
                                            <Select
                                                labelId="state_label"
                                                id="state_select"
                                                defaultValue={"Alabama"}
                                                value={shippingState}
                                                label="State"
                                                onChange={handleShippingState}
                                            >
                                            {states.map((index, key) => (
                                            <MenuItem key={key} value={index}>{index}</MenuItem>
                                            ))}
                                            
                                        


                                            </Select>

                                        </FormControl>

                        

                                    <TextField 
                                        variant="outlined"
                                        type="text"
                                        label="Shipping City"
                                        onChange={handleShippingCity}/>

                                    <TextField 
                                        variant="outlined"
                                        type="text"
                                        label="Shipping Address"
                                        onChange={handleShippingInput}/>

                                    <TextField 
                                        variant="outlined"
                                        type="text"
                                        label="Full Address (optional)"
                                        onChange={handleFullShippingInput}/>

                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        type="submit">
                                            Create 
                                        </Button>

                                </Stack>
                                </form>

                            </Stack>

                        </DialogContent>

                       

                    </Dialog>


                    {/** Edit Shipping Address */}

                    <Dialog
                        fullScreen
                        open={editShipping}
                        onClose={handleEditShipping}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleEditShipping}
                            aria-label="close"
                            >
                            <CloseIcon />
                            </IconButton>

                        </Toolbar>
                        </AppBar>


                        <DialogContent>

                            <Stack 
                                alignItems="center" 
                                justifyContent="center"
                                direction="column"
                                spacing={3}>

                                <Typography 
                                    variant="h6"
                                    component="div"
                                    fontFamily="monospace">
                                        Edit Shipping Info 
                                </Typography>

                                <form onSubmit={submitEditShipping}>

                                <Stack 
                                    alignItems="center" 
                                    justifyContent="center"
                                    direction="column"
                                    spacing={3}>

                                        <FormControl fullWidth>

                                            <InputLabel id="country_label">Country</InputLabel>
                                            <Select
                                            labelId="country_label"
                                            id="country_select"
                                            value={shippingCountryEdit}
                                            label="Country"
                                            onChange={handleShippingCountryEdit}
                                            >
                                            <MenuItem value="United States">United States</MenuItem>
                                            </Select>

                                        </FormControl>


                                        <FormControl fullWidth>

                                            <InputLabel id="state_label">State</InputLabel>
                                            <Select
                                                labelId="state_label"
                                                id="state_select"
                                                defaultValue={"Alabama"}
                                                value={shippingStateEdit}
                                                label="State"
                                                onChange={handleShippingStateEdit}
                                            >
                                            {states.map((index, key) => (
                                            <MenuItem key={key} value={index}>{index}</MenuItem>
                                            ))}
                                            
                                        


                                            </Select>

                                        </FormControl>

                        

                                    <TextField 
                                        variant="outlined"
                                        type="text"
                                        label="Shipping City"
                                        onChange={handleShippingCityEdit}/>

                                    <TextField 
                                        variant="outlined"
                                        type="text"
                                        label="Shipping Address"
                                        onChange={handleShippingEdit}/>

                                    <TextField 
                                        variant="outlined"
                                        type="text"
                                        label="Full Address (optional)"
                                        onChange={handleFullShippingEdit}/> 

                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        type="submit">
                                            Update
                                        </Button>

                                </Stack>
                                </form>

                            </Stack>

                        </DialogContent>

                       

                    </Dialog>



                    {/** Delete Shipping Info  */}


                    <Dialog
                        open={deleteShipping}
                        onClose={handleDeleteShipping}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleDeleteShipping}
                            aria-label="close"
                            >
                            <CloseIcon />
                            </IconButton>

                        </Toolbar>
                        </AppBar>


                        <DialogContent>

                            <Stack 
                                alignItems="center" 
                                justifyContent="center"
                                direction="column"
                                spacing={3}>

                                <Typography 
                                    variant="h6"
                                    component="div"
                                    fontFamily="monospace">
                                        Are you sure you want to Delete?
                                </Typography>

                                

                                <Stack 
                                    alignItems="center" 
                                    justifyContent="center"
                                    direction="column"
                                    spacing={3}>


                                    <Button 
                                        variant="contained"
                                        color="error"
                                        type="submit"
                                        onClick={() => ( submitDeleteShipping(shippingID))}>
                                            Delete 
                                        </Button>

                                </Stack>

                            </Stack>

                        </DialogContent>

                       

                    </Dialog>



                {/** Transaction Info  */}


                <Dialog
                        fullScreen 
                        open={viewTransaction}
                        onClose={handleViewTransaction}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleViewTransaction}
                            aria-label="close"
                            >
                            <CloseIcon />
                            </IconButton>

                        </Toolbar>
                        </AppBar>


                        <DialogContent>

                            <Stack 
                                maxWidth="100%"
                                alignItems="center" 
                                justifyContent="center"
                                direction="column"
                                spacing={3}>

                                    <Typography 
                                        variant="h4"
                                        component="div"
                                        fontFamily="monospace"
                                        >
                                            Recent Orders 
                                    </Typography>


                                    <Stack 
                                        width="80%"
                                        direction="column"
                                        spacing={3}
                                        alignItems="center"
                                        justifyContent="center">

                                            {recentTransactions.map((order, key) => (
                                                <Card 
                                                    key={key}
                                                    >

                                                        <CardContent>

                                                            <Stack 
                                                                direction="column"
                                                                spacing={1}>

                                                                    <Chip 
                                                                        variant="outlined"
                                                                        size="large"
                                                                        label={`Transaction ID: ${order.id} `}/>

                                                                    <Chip 
                                                                        variant="outlined"
                                                                        size="large"
                                                                        label={`Shipping: ${order.shipping} `}/>    

                                                                    <Chip 
                                                                        variant="outlined"
                                                                        size="large"
                                                                        label={`Price Paid: $${order.price} `}/>  

                                                                    <Chip 
                                                                        variant="outlined"
                                                                        size="large"
                                                                        color="success"
                                                                        label={`Tracking Number: ${order.tracking_number} `}/> 

                                                                    <Button 
                                                                        variant="text"
                                                                        onClick={() => (
                                                                            updateViewOrder(true),
                                                                            mountSpecificTransaction(order.id)
                                                                        )}>
                                                                            View 
                                                                    </Button>            

                                                            </Stack>

                                                        </CardContent>

                                                </Card>

                                                
                                            ))}

                                    </Stack>
                                

                                

                                <Stack 
                                    maxWidth="100%"
                                    alignItems="center" 
                                    justifyContent="center"
                                    direction="column"
                                    spacing={3}>


                                   

                                </Stack>

                            </Stack>

                        </DialogContent>

                       

                    </Dialog>


                     {/** Order Info  */}


                <Dialog
                        fullScreen 
                        open={viewOrder}
                        onClose={handleViewOrders}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleViewOrders}
                            aria-label="close"
                            >
                            <CloseIcon />
                            </IconButton>

                        </Toolbar>
                        </AppBar>


                        <DialogContent>

                            <Stack 
                                maxWidth="100%"
                                alignItems="center" 
                                justifyContent="center"
                                direction="column"
                                spacing={3}>

                                    <Typography 
                                        variant="h4"
                                        component="div"
                                        fontFamily="monospace"
                                        >
                                            Orders 
                                    </Typography>


                                    <Stack 
                                        width="80%"
                                        direction="column"
                                        spacing={3}
                                        alignItems="center"
                                        justifyContent="center">


                                            {recentOrders.map((order, key) => (
                                                <Card
                                                    key={key}>

                                                        <CardContent>
                                                            <Stack 
                                                                direction="row"
                                                                spacing={1}
                                                                >

                                                                    <CardMedia
                                                                        sx={{height: '10rem', width: '10rem'}}
                                                                        component="img"
                                                                        image={order.product_images[0].url}
                                                                        alt={order.name}/>

                                                                    <Stack 
                                                                        direction="column"
                                                                        spacing={2}>

                                                                        <Chip 
                                                                            variant="outlined"
                                                                            size="large"
                                                                            label={order.name}/>

                                                                        <Chip 
                                                                            variant="outlined"
                                                                            size="large"
                                                                            label={` $${order.product_price} ${order.currency}`}/>
                                                                         <Chip 
                                                                            variant="outlined"
                                                                            size="large"
                                                                            label={order.description}/>

                                                                    </Stack>

                                                                   

                                                            </Stack>
                                                        </CardContent>

                                                </Card>
                                            ))}

                                            

                                    </Stack>
                                

                                

                                <Stack 
                                    maxWidth="100%"
                                    alignItems="center" 
                                    justifyContent="center"
                                    direction="column"
                                    spacing={3}>


                                   

                                </Stack>

                            </Stack>

                        </DialogContent>

                       

                    </Dialog>

                   

                    <Snackbar
                            open={errorInfoStatus}
                            autoHideDuration={6000}
                            onClose={handleErrorInfo}>
                                <Alert 
                                    variant="filled"
                                    severity={errorColor}>
                                     {errorMessage}
                                </Alert>
                    </Snackbar>
                   


            </Box>
       
    )
}


export default Profile 