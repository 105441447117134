import React, {useState, useEffect} from 'react' 

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack' 

import Button from '@mui/material/Button'

import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'


import Typography from '@mui/material/Typography'


import homescreen_image from '../brand_shot/2-IMG_3064.jpg'
import home_one from '../brand_shot/43-IMG_3174.jpg'
import home_two from '../brand_shot/40-IMG_3166.jpg'
import home_three from '../brand_shot/19-IMG_3120.jpg'
import home_four from '../brand_shot/7-IMG_3077.jpg'
import home_five from '../brand_shot/33-IMG_3152.jpg'

import { makeStyles } from '@mui/styles'

import {Link} from 'react-router-dom'




import {
    FiCard,
    FiCardActionArea,
    FiCardActions,
    FiCardContent,
    FiCardMedia
  } from "../Components/FullCardImage"

  const useStyles = makeStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },


  
    media: {
      height: {xs: '500px', sm: '500px', md: '500px', lg: '800px', xl: '800px'}
    },

    fiCardContent: {
      color: "#ffffff",
      backgroundColor: "rgba(0,0,0,.44)",
      height: '100%'
    },
    fiCardContentTextSecondary: {
      color: "rgba(255,255,255,0.78)"
    }
  });

  
function HomeScreen() {

    const classes = useStyles() 
    return (
        <Box 
            
            justifyContent="center"
            alignItems="center"
            maxWidth="100%">

                <Stack 
                     
                    direction="column"
                    spacing={3}>

                        
                            <Box
                                display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex"}}
                                justifyContent="center"
                                alignItems="center"
                                        >
                                   

                                    <FiCard
                                        sx={{ 
                                                height: {xs: '500px', sm: '500px', md: '500px', lg: '500px', xl: '500px', xxl: '800px'},
                                                width: {xs: '90%', sm: '90%', md: '90%', lg: '90%', xl: '90%'},
                                            }}>
                                    <FiCardMedia
                                       
                                        media="picture"
                                        alt="Contemplative Reptile"
                                        image={homescreen_image}
                                        title="Contemplative Reptile"
                                    />


                                    <FiCardContent className={classes.fiCardContent}>

                                        <Stack 
                                            direction="column"
                                            spacing={-2}
                                            >
                                            <Typography 
                                                gutterBottom 
                                                variant='h3'
                                                component="h1"
                                                fontWeight="bold"
                                                fontFamily="monospace">
                                                    GodlyVizion 
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                fontFamily="monospace"
                                                className={classes.fiCardContentTextSecondary}
                                                component="div"
                                                >

                                                Vizionary Clothing Store 
                            
                                            </Typography>

                                        </Stack>

                                        <Button component={Link} to={'/store'}
                                            sx={{ fontFamily: 'monospace', backgroundColor: 'purple', background: 'purple'}}
                                            variant="contained">
                                                Browse Store 
                                            </Button>

                                    </FiCardContent>


                                    
                                    </FiCard>
                                </Box>
                                
                        </Stack>


                        <Stack 
                            mt={10}
                            direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row'}}
                            spacing={3}
                            alignItems="center"
                            justifyContent="center">


                                <FiCard
                                        sx={{ 
                                                height: {xs: '300px', sm: '300px', md: '300px', lg: '300px', xl: '300px', xxl: '500px'},
                                                width: {xs: '90%', sm: '90%', md: '50%', lg: '40%', xl: '40%'},
                                            }}>
                                    <FiCardMedia
                                       
                                        media="picture"
                                        alt="Contemplative Reptile"
                                        image={home_one}
                                        title="Contemplative Reptile"
                                    />


                                    <FiCardContent className={classes.fiCardContent}>

                                        <Stack 
                                            direction="column"
                                            spacing={-2}
                                            >
                                            <Typography 
                                                gutterBottom 
                                                variant='h3'
                                                component="h1"
                                                fontWeight="bold"
                                                fontFamily="monospace">
                                                    GodlyVizion 
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                fontFamily="monospace"
                                                className={classes.fiCardContentTextSecondary}
                                                component="div"
                                                >

                                                Vizionary Clothing Store 
                            
                                            </Typography>

                                        </Stack>

                                        <Button component={Link} to={'/store'}
                                            sx={{ fontFamily: 'monospace', backgroundColor: 'purple', background: 'purple'}}
                                            variant="contained">
                                                Browse Store 
                                            </Button>

                                    </FiCardContent>


                                    
                                    </FiCard>



                                    <FiCard
                                        sx={{ 
                                                height: {xs: '300px', sm: '300px', md: '300px', lg: '300px', xl: '300px', xxl: '500px'},
                                                width: {xs: '90%', sm: '90%', md: '50%',  lg: '40%', xl: '40%'},
                                            }}>
                                    <FiCardMedia
                                       
                                        media="picture"
                                        alt="Contemplative Reptile"
                                        image={home_two}
                                        title="Contemplative Reptile"
                                    />


                                    <FiCardContent className={classes.fiCardContent}>

                                        <Stack 
                                            direction="column"
                                            spacing={-2}
                                            >
                                            <Typography 
                                                gutterBottom 
                                                variant='h3'
                                                component="h1"
                                                fontWeight="bold"
                                                fontFamily="monospace">
                                                    GodlyVizion 
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                fontFamily="monospace"
                                                className={classes.fiCardContentTextSecondary}
                                                component="div"
                                                >

                                                Vizionary Clothing Store 
                            
                                            </Typography>

                                        </Stack>

                                        <Button component={Link} to={'/store'}
                                            sx={{ fontFamily: 'monospace', backgroundColor: 'purple', background: 'purple'}}
                                            variant="contained">
                                                Browse Store 
                                            </Button>

                                    </FiCardContent>


                                    
                                    </FiCard>
                            </Stack>


                            <Stack 
                                mt={10}
                                direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row'}}
                                spacing={3}
                                alignItems="center"
                                justifyContent="center">


                                    <FiCard
                                        sx={{ 
                                                height: {xs: '300px', sm: '300px', md: '300px', lg: '300px', xl: '300px', xxl: '500px'},
                                                width: {xs: '90%', sm: '90%', md: '50%', lg: '30%', xl: '30%'},
                                            }}>
                                    <FiCardMedia
                                       
                                        media="picture"
                                        alt="Contemplative Reptile"
                                        image={home_three}
                                        title="Contemplative Reptile"
                                    />


                                    <FiCardContent className={classes.fiCardContent}>

                                        <Stack 
                                            direction="column"
                                            spacing={-2}
                                            >
                                            <Typography 
                                                gutterBottom 
                                                variant='h3'
                                                component="h1"
                                                fontWeight="bold"
                                                fontFamily="monospace">
                                                    GodlyVizion 
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                fontFamily="monospace"
                                                className={classes.fiCardContentTextSecondary}
                                                component="div"
                                                >

                                                Vizionary Clothing Store 
                            
                                            </Typography>

                                        </Stack>

                                        <Button component={Link} to={'/store'}
                                            sx={{ fontFamily: 'monospace', backgroundColor: 'purple', background: 'purple'}}
                                            variant="contained">
                                                Browse Store 
                                            </Button>

                                    </FiCardContent>


                                    
                                    </FiCard>


                                    <FiCard
                                        sx={{ 
                                                height: {xs: '300px', sm: '300px', md: '300px', lg: '300px', xl: '300px', xxl: '500px'},
                                                width: {xs: '90%', sm: '90%', md: '50%',  lg: '30%', xl: '30%'},
                                            }}>
                                    <FiCardMedia
                                       
                                        media="picture"
                                        alt="Contemplative Reptile"
                                        image={home_four}
                                        title="Contemplative Reptile"
                                    />


                                    <FiCardContent className={classes.fiCardContent}>

                                        <Stack 
                                            direction="column"
                                            spacing={-2}
                                            >
                                            <Typography 
                                                gutterBottom 
                                                variant='h3'
                                                component="h1"
                                                fontWeight="bold"
                                                fontFamily="monospace">
                                                    GodlyVizion Hat 
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                fontFamily="monospace"
                                                className={classes.fiCardContentTextSecondary}
                                                component="div"
                                                >

                                                In Store Now 
                            
                                            </Typography>

                                        </Stack>

                                        <Button component={Link} to={'/store'}
                                            sx={{ fontFamily: 'monospace', backgroundColor: 'purple', background: 'purple'}}
                                            variant="contained">
                                                Browse Store 
                                            </Button>

                                    </FiCardContent>


                                    
                                    </FiCard>


                                    <FiCard
                                        sx={{ 
                                                height: {xs: '300px', sm: '300px', md: '300px', lg: '300px', xl: '300px', xxl: '500px'},
                                                width: {xs: '90%', sm: '90%', md: '50%',  lg: '30%', xl: '30%'},
                                            }}>
                                    <FiCardMedia
                                       
                                        media="picture"
                                        alt="Contemplative Reptile"
                                        image={home_five}
                                        title="Contemplative Reptile"
                                    />


                                    <FiCardContent className={classes.fiCardContent}>

                                        <Stack 
                                            direction="column"
                                            spacing={-2}
                                            >
                                            <Typography 
                                                gutterBottom 
                                                variant='h3'
                                                component="h1"
                                                fontWeight="bold"
                                                fontFamily="monospace">
                                                    GodlyVizion 
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                fontFamily="monospace"
                                                className={classes.fiCardContentTextSecondary}
                                                component="div"
                                                >

                                                Vizionary Clothing Store 
                            
                                            </Typography>

                                        </Stack>

                                        <Button component={Link} to={'/store'}
                                            sx={{ fontFamily: 'monospace', backgroundColor: 'purple', background: 'purple'}}
                                            variant="contained">
                                                Browse Store 
                                            </Button>

                                    </FiCardContent>


                                    
                                    </FiCard>


                            </Stack>





        </Box>
    )
}

export default HomeScreen 