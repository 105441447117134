import React, {useState} from 'react' 

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { DialogContent, Stack } from '@mui/material'
import mylogo from '../logo/logo512.png'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const Shipping = ({ status, handler}) => {

    return (
            <Dialog
                fullScreen
                open={status}
                onClose={handler}
                TransitionComponent={Transition}>

                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close">
                        <CloseIcon />
                    </IconButton>

                    <Typography 
                        variant="h6"
                        component="div">
                            Shipping Policy 
                    </Typography>
                   
                    
                </Toolbar>
                </AppBar>


                <DialogContent>
                    <Stack
                        direction="column"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center">

                            <img src={mylogo} width="10%" height="10%" />
                            
                        <Stack
                            width={{sm: '100%', md: '60%'}}
                            direction="column"
                            spacing={1}>
                           
                            <Typography 
                                variant="h4"
                                component="div"
                                justifyContent="center"
                                alignItems="center">
                                    all order/products will be shipped through USPS Priority Mail; typical delivery is in 2-5 days however some orders may require additional time.                            </Typography>
                        </Stack>


                    </Stack>
                </DialogContent>
            
            </Dialog>
    )

}

export default Shipping 

