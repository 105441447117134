
import React from 'react' 

import Snackbar from '@mui/material/Snackbar' 
import Alert from '@mui/material/Alert' 

function Message(props){


    const {messageStatus, message, messageIndicator, messageClose} = props 

    return (
        <Snackbar 
            open={messageStatus}
            autoHideDuration={6000}
            onClose={messageClose}>

                <Alert 
                    severity={messageIndicator}>
                        {message} 
                </Alert>


        </Snackbar>
            
    )
}

export default Message 