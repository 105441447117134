import React , {useState, useEffect, useRef, useLayoutEffect} from 'react'

import Box from '@mui/material/Box' 


import HomeScreen from './Pages/HomeScreen'
import AppMenu from './Components/AppMenu' 
import AuthMenu from './Components/AuthMenu' 
import AppFooter from './Components/AppFooter'

import {getLocalAccessToken} from './Session/AuthSession'
import { Routes, Route, Link, BrowserRouter } from "react-router-dom"




function App() {


    const [isAuthenticated, setAuthentication] = useState(false)
  



      useEffect(() => {




        const checkAuth = setInterval(() => {
          const token = getLocalAccessToken() 
          if(token){
            return setAuthentication(true)
          }
          return setAuthentication(false)

        }, 1)


        return () => clearInterval(checkAuth) 
      }, [])


    


    


    return(

          <BrowserRouter>

              {!isAuthenticated ? (

                <Box
                  justifyContent="center"
                  alignItems="center"
                  maxWidth="100%">
                    <AppMenu/>
                    <AppFooter/>
                </Box>

              ) : (
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    maxWidth="100%">
                      <AuthMenu/>
                      <AppFooter/>
                  </Box>

              )}

          </BrowserRouter>


    )
  
  
}




export default App 